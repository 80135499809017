import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { SiteContext } from "../../context/site";

function Navlinks({ closeMobileNav, ...rest }) {
	const { showContactForm } = useContext(SiteContext);
	const { pathname } = useLocation();
	const path = pathname.toLowerCase();

	return (
		<React.Fragment>
			{/* <NavLink to="/faq" activeClassName="">
				FAQs
			</NavLink> */}
			<li>
				<NavLink
					className="menu-link cursive"
					activeClassName={path === "/about-us" ? "selected" : ""}
					to="/About-us"
					data-text="ABOUT US"
					onClick={() => closeMobileNav()}
				>
					ABOUT US
				</NavLink>
			</li>

			<li>
				<NavLink
					className="menu-link cursive"
					activeClassName={path === "/thing-to-do" ? "selected" : ""}
					to="/Things-To-Do"
					data-text="THINGS TO DO"
					onClick={() => closeMobileNav()}
				>
					THINGS TO DO
				</NavLink>
			</li>

			<li>
				<NavLink
					className="menu-link cursive"
					activeClassName=""
					data-text="MAPS / DIRECTIONS"
					to="/Maps"
					onClick={() => closeMobileNav()}
				>
					MAPS / DIRECTIONS
				</NavLink>
			</li>

			<li>
				<NavLink
					className="menu-link cursive"
					activeClassName=""
					data-text="RATES"
					to="/Rates"
					onClick={() => closeMobileNav()}
				>
					RATES
				</NavLink>
			</li>

			<li>
				<NavLink
					className="menu-link cursive"
					activeClassName=""
					data-text="CONTACT US"
					to="/Contact"
					onClick={() => closeMobileNav()}
					style={{ marginRight: "0" }}
				>
					CONTACT US
				</NavLink>
			</li>
		</React.Fragment>
	);
}

export default Navlinks;
