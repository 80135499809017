export const removeScript = (scriptToremove) => {
   let allsuspects = document.getElementsByTagName('script');
   for (let i = allsuspects.length; i >= 0; i--) {
      if (allsuspects[i] && allsuspects[i].getAttribute('src') !== null && allsuspects[i].getAttribute('src').indexOf(`${scriptToremove}`) !== -1) {
         allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
   }
};

export const appendScript = (scriptToAppend, onload) => {
   const script = document.createElement('script');
   script.src = scriptToAppend;
   script.async = true;
   script.onload = onload;
   document.body.appendChild(script);
};

export const removeLink = (scriptToremove) => {
   let allsuspects = document.getElementsByTagName('link');
   for (let i = allsuspects.length; i >= 0; i--) {
      if (allsuspects[i] && allsuspects[i].getAttribute('href') !== null && allsuspects[i].getAttribute('href').indexOf(`${scriptToremove}`) !== -1) {
         allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
   }
};

export const appendLink = (scriptToAppend, onload) => {
   const script = document.createElement('link');
   script.href = scriptToAppend;
   script.rel = 'stylesheet';
   document.body.appendChild(script);
};
