import React, { useEffect } from 'react';
import { appendScript, removeScript } from '../../utils/scripts';
import Camper from './images/camper.jpg';
// import Form from "./form";

// import Form from '../../components/Footer/form';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div className="contact container">
      <section className="row">
        <div className="col s12 m12 l12 center" style={{ marginBottom: '20px' }}>
          <h2 className="abstractTextColor cursive" style={{ marginBottom: 0, paddingBottom: 0, marginTop: '20px' }}>
            CONTACT US
          </h2>
        </div>
        <div className="clearfix"></div>
        {/* <div className="hide-on-small-only col m4">
               <img src={Camper} alt="" className="responsive-img" />
            </div> */}
        <div className="wrap-contact">
          <div className="col s12 m6 l6 bgContact">
            <p className="">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </p>
          </div>
          <div className="col s12 m6 l6 card center" style={{ margin: '0', paddingTop: '50px', paddingBottom: '50px' }}>
            <p style={{ fontWeight: '200', marginBottom: '0' }}>
              <span className="abstractTextColor cursive center" style={{ fontWeight: '400', fontSize: '52px' }}>
                Hardeeville RV
              </span>
              <br />
              3090 S. Okatie Highway - Hwy 315
              <br />
              Hardeeville, South Carolina 29927
            </p>
            <p style={{ fontWeight: '200' }}>
              <span>
                Mobile{' '}
                <a style={{ fontWeight: '400', fontSize: '25px' }} href="tel:8433845696">
                  843.384.5696
                </a>
              </span>
            </p>
            {/* <Form /> */}
          </div>
        </div>
        <br />
        <br />
        <br />
        {/* <div className="col s12 m8 offset-m2 center">
					<p>
						Hardeeville RV
						<br />
						3090 S. Okatie Highway - Hwy 315
						<br />
						Hardeeville, South Carolina 29927
					</p>
					<Form />
				</div> */}
      </section>
    </div>
  );
};

export default HomePage;
