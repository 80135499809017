import React from 'react';
import { Helmet } from 'react-helmet';
import Route, { HomeRoute } from './routes';
// import Favicon from "react-favicon";
// import FaviconImg from "./images/favicon.ico";
import HomePage from './containers/HomePage';
import AboutUsPage from './containers/AboutUsPage';
import ThingsToDoPage from './containers/ThingsToDoPage';
import MapsPage from './containers/MapsPage';
import RatesPage from './containers/RatesPage';

import OurPropertiesPage from './containers/OurPropertiesPage';
import ContactPage from './containers/ContactPage';
import { ScrollToTop } from './components/ScrollTopArrow';

import NotFoundPage from './containers/NotFoundPage';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import SiteProvider from './context/site';

const App = () => {
   return (
      <SiteProvider>
         <Router>
            {/* <Favicon url={FaviconImg} /> */}
            <ScrollToTop />
            <React.Fragment>
               <Switch>
                  <HomeRoute
                     exact
                     path="/"
                     component={(props) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Hardeeville RV Park</title>
                                 <meta
                                    name="description"
                                    content="Hardeeville RV Park, Camping, Campers, RVer, RVers, MotorHomes, Trailers, Travel, RVs, Motorcoaches, Motorcoach, Recreation, Tourism, Recreation Vehicles, Campsites, Outdoors, rv parks, motorhomes, recreation vehicles, SC, S.C., South Carolina, Beaufort, Sun City, Hilton Head, Savannah, Georgia, GA, family RV sites"
                                 />
                                 <meta name="keywords" content="Hardeeville RV Park ï¿½ Your Home Away from Home. Located just minutes from Beaufort, Sun City, and Hilton Head South Carolina and Savannah, Georgia." />
                              </Helmet>
                              <HomePage {...props} />
                           </>
                        );
                     }}
                  />
                  <HomeRoute
                     exact
                     path="/Admin"
                     component={(props) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Hardeeville RV Park</title>
                                 <meta
                                    name="description"
                                    content="Hardeeville RV Park, Camping, Campers, RVer, RVers, MotorHomes, Trailers, Travel, RVs, Motorcoaches, Motorcoach, Recreation, Tourism, Recreation Vehicles, Campsites, Outdoors, rv parks, motorhomes, recreation vehicles, SC, S.C., South Carolina, Beaufort, Sun City, Hilton Head, Savannah, Georgia, GA, family RV sites"
                                 />
                                 <meta name="keywords" content="Hardeeville RV Park ï¿½ Your Home Away from Home. Located just minutes from Beaufort, Sun City, and Hilton Head South Carolina and Savannah, Georgia." />
                              </Helmet>
                              <HomePage {...props} Admin />
                           </>
                        );
                     }}
                  />
                  <Route
                     exact
                     path="/About-Us"
                     component={(p) => {
                        return (
                           <>
                              <Helmet>
                                 <title>About Us</title>
                              </Helmet>
                              <AboutUsPage {...p} />
                           </>
                        );
                     }}
                  />
                  <Route
                     exact
                     path="/Things-To-Do"
                     component={(p) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Things To Do</title>
                              </Helmet>
                              <ThingsToDoPage {...p} />
                           </>
                        );
                     }}
                  />
                  <Route
                     exact
                     path="/Maps"
                     component={(p) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Maps / Directions</title>
                              </Helmet>
                              <MapsPage {...p} />
                           </>
                        );
                     }}
                  />
                  <Route
                     exact
                     path="/Our-Properties"
                     component={(p) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Our Properties</title>
                              </Helmet>
                              <OurPropertiesPage {...p} />
                           </>
                        );
                     }}
                  />
                  <Route
                     exact
                     path="/Rates"
                     component={(p) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Contact Us</title>
                              </Helmet>
                              <RatesPage {...p} />
                           </>
                        );
                     }}
                  />
                  <Route
                     exact
                     path="/Contact"
                     component={(p) => {
                        return (
                           <>
                              <Helmet>
                                 <title>Contact Us</title>
                              </Helmet>
                              <ContactPage {...p} />
                           </>
                        );
                     }}
                  />
                  <Route component={NotFoundPage} />
               </Switch>
            </React.Fragment>
         </Router>
      </SiteProvider>
   );
};

export default App;
