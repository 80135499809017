import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SiteContext } from '../../context/site';
import M from 'materialize-css';
// import Form from '../../components/Footer/form';
import FooterLogos from '../../images/logo-FOOTER.png';

// const topStyle = {};

const Footer = () => {
  const { showContactForm } = useContext(SiteContext);

  useEffect(() => {
    M.Sidenav.init(document.getElementById('mobile-nav'));
  }, []);

  return (
    <div>
      <div className="themeBgColor">
        <div className="container center white-text">
          <footer className="row" style={{ margin: '0' }}>
            {/* <ul
							className="hide-on-med-and-down"
							style={{ marginTop: "0", paddingTop: "30px" }}
						>
							<li>
								<Link className="" to="/Our-Philosophy">
									OUR PHILOSOPHY
								</Link>
							</li>
							<li>
								<Link className="" to="/Our-Staff">
									OUR STAFF
								</Link>
							</li>
							<li>
								<Link className="" to="/Our-Community">
									OUR COMMUNITY
								</Link>
							</li>
							<li>
								<a
									rel="noopener noreferrer"
									target="_blank"
									className=""
									href="https://www.holtzmanrealestate.com/"
								>
									OUR PROPERTIES
								</a>
							</li>
							<li>
								<a onClick={() => showContactForm()} className="" href="#!">
									CONTACT US
								</a>
							</li>
						</ul> */}
            <div className="col s12 m12 l12">
              <div className="">
                <p style={{ fontWeight: '200', marginBottom: '0' }}>
                  <span style={{ fontWeight: '400', fontSize: '22px' }}>Hardeeville RV</span>
                  <br />
                  3090 S. Okatie Highway - Hwy 315
                  <br />
                  Hardeeville, South Carolina 29927
                </p>
                <p style={{ fontWeight: '200' }}>
                  Call or Text
                  <br />
                  <span style={{ fontWeight: '400', fontSize: '25px' }}>
                    <a style={{ color: 'white' }} href="tel:8433845696">
                      843.384.5696
                    </a>
                  </span>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
            <p
              style={{
                fontSize: '14px',
                marginTop: '0',
                textAlign: 'center',
              }}
            >
              &copy; 2021 Hardeeville RV | Crafted and Hosted by{' '}
              <a href="https://thesabresolution.com/" rel="noopener noreferrer" target="_blank" className="white-text">
                Sabre Creative
              </a>
            </p>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Footer;
