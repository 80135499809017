import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const DefaultLayout = (props) => {
	return (
		<div id="wrapper">
			<Header
				path={props.children.props.location.pathname}
				template="default"
			/>
			{props.children}
			<Footer template="home" />
		</div>
	);
};

export default DefaultLayout;
