import React, { useEffect } from 'react';
import { appendScript, removeScript } from '../../utils/scripts';
import OfficeHouse from '../../images/About-rvoffice.jpeg';
import RulesAndRegulations from './HardeevilleRVRulesRegulations.pdf';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  return (
    <div>
      <div className="" style={{ paddingBottom: '50px' }}>
        <div className="row container ">
          <div className="col s12 m12 l12 center">
            <h2 className="abstractTextColor cursive" style={{ marginBottom: 0, paddingBottom: 0, marginTop: '20px' }}>
              ABOUT US
            </h2>
          </div>
          <div className="col s12 m12 l12">
            <div className="content800">
              <p className="center">Hardeeville RV is family owned and operated. Located between historic Savannah, Georgia and Bluffton, South Carolina. Just 10 minutes from downtown Savannah and 30 minutes to historic Beaufort, South Carolina, Hilton Head, South Carolina and Tybee Island, Georgia.</p>
              <p>
                <img src={OfficeHouse} style={{ width: '100%' }} />
              </p>
              <p>Located at 3090 South Okatie Highway, Hardeeville, South Carolina, 29977 in a beautiful, natural surrounding, our well maintained, peaceful park offers full hook-up sites with cable tv, wifi, and laundry facilities. Also, the park has on site dumpster disposal. All of the sites have 20, 30, 50 amp service. You may also purchase your own dedicated internet line at your site through the local provider Hargray Communications. The park also has a large, clean dog park. Less than 5 minutes away are two convenience stores and a Dollar General. You may receive mail and/or packages at the RV Park.</p>
              <p>At this time we only offer monthly rates. No overnight or short time stays. All sites are limited to two people per site and generally two vehicles. We do require an electric deposit upon check-in, currently $125.00 (Rate subject to change). That deposit is applied to your final electric bill upon check out. Please be reminded when you receive your monthly rental receipt, your electric bill is for the previous month and your rent is for the current month. Electric is billed at 11 cent per kilowatt/hour.</p>
              <p>
                Please call/text cell number <a href="tel:843-384-5696">843-384-5696</a> for current monthly rate.
              </p>
              <p>
                <b>We DO NOT accept credit or debit cards - Checks, Cash, or Money Orders ONLY.</b>
              </p>
            </div>
            <br />
            <h2 className="abstractTextColor cursive center" style={{ marginBottom: '0' }}>
              RULES AND REGULATIONS
            </h2>
            <p className="center" style={{ marginTop: '0' }}>
              <a target="_blank" href={RulesAndRegulations} rel="noopener noreferer" className="btn">
                DOWNLOAD PDF
              </a>
            </p>
            <br />

            <h2 className="abstractTextColor cursive center">
              EMERGENCY AND <span style={{ whiteSpace: 'nowrap' }}>NON-EMERGENCY</span> LISTINGS
            </h2>
            <div className="col s12 m6 l6">
              <blockquote>
                <h3>
                  <b>WE EMERGENCY</b>
                  <br />
                  911
                </h3>
              </blockquote>

              <blockquote>
                <h3>
                  <b>JASPER COUNTY SHERIFF’S DEPARTMENT</b>
                  <br />
                  843.726.7777
                </h3>
              </blockquote>
              <blockquote>
                <h3>
                  <b>HARGRAY PHONE and CABLE</b>
                  <br />
                  843.784.2211
                  <br />
                  100 Main Street, Hardeeville, SC
                </h3>
              </blockquote>
              <blockquote>
                <h3>
                  <b>HOSPITAL</b>
                  <br />
                  843.784.8000
                  <br />
                  Coastal Carolina Medical
                  <br />
                  Center 1000 Medical Center Drive, Hardeeville, SC
                  <br />
                  At intersection of Hwy 278 and I-95
                </h3>
              </blockquote>
            </div>
            <div className="col s12 m6 l6">
              <blockquote>
                <h3>
                  <b>JASPER COUNTY STORM SHELTER</b>
                  <br />
                  Hwy 278, 913 Grays Hwy. RIdgeland, SC
                </h3>
              </blockquote>
              <blockquote>
                <h3>
                  <b>JACKS RV SERVICE</b>
                  <br />
                  912.312.5975
                </h3>
              </blockquote>
              <blockquote>
                <h3>
                  <b>FRANKLINS TIRE AND SERVICE CENTER</b>
                  <br />
                  843.784.6611
                  <br />
                  Hwy 17 North, Hardeeville, SC
                </h3>
              </blockquote>

              <blockquote>
                <h3>
                  <b>WAL-MART SUPER CENTER</b>
                  <br />
                  Main: 843.208.3000 | Pharmacy: 843.208.3010 | Tire and Lube: 843.208.3021
                  <br />
                  4400 Hwy 278, Hardeeville, SC
                </h3>
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
