import React, { useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ArrowToTop from '../ScrollTopArrow';
import '../../styles/app.css';
import NavLinks from './navlinks';
import M from 'materialize-css';
import logo from '../../images/Logo-HOME.png';
import Bus from '../../images/hero-bus.jpg';
import '../../styles/dropdown.css';
import { SiteContext } from '../../context/site';

const topStyle = {
  border: '0',
  boxShadow: '0 0 0',
  height: '135px',
};

const Home = ({ noHero }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    M.Sidenav.init(document.getElementById('mobile-nav'));
  }, []);

  const closeMobileNav = () => {
    let elem = document.getElementById('mobile-nav');
    let instance = M.Sidenav.getInstance(elem);
    instance.close();
  };

  return (
    <div className="">
      <div className="themeBgColor center white-text">
        <p className="addLine hide-on-small-only">
          <span className="no-wrap-text">3090 South Okatie Hwy</span> &#8226; <span className="no-wrap-text">Hwy 315</span> &#8226; <span className="no-wrap-text">Hardeeville, SC 29927</span> |{' '}
          <span className="no-wrap-text">
            mobile{' '}
            <a style={{ color: 'white' }} href="tel:8433845696">
              843.384.5696
            </a>
          </span>
        </p>
        <div className="addLine show-on-small" style={{ padding: '0' }}>
          <NavContent />
          <span className="no-wrap-text">3090 South Okatie Hwy</span> &#8226; <span className="no-wrap-text">Hwy 315</span>
          <br />
          <span className="no-wrap-text">Hardeeville, SC 29927</span>
          <br />
          <span className="no-wrap-text">
            <a style={{ color: 'white', fontSize: '25px', fontWeight: '400' }} href="tel:8433845696">
              843.384.5696
            </a>{' '}
            mobile
          </span>
          {pathname === '/' ? (
            <div>
              <br />

              <img src={Bus} className="responsive-img" />
            </div>
          ) : (
            <div>
              <br />
            </div>
          )}
        </div>
      </div>
      <div className="hide-on-small-only">
        {pathname === '/' ? (
          <HomePageView noHero={noHero}>
            <NavContent closeMobileNav={closeMobileNav} />
          </HomePageView>
        ) : (
          <InterierPageView noHero={noHero}>
            <NavContent closeMobileNav={closeMobileNav} whiteText />
          </InterierPageView>
        )}
      </div>
      <ul className="sidenav uppercaseTxt" id="mobile-nav">
        <li style={{ height: '160px' }}>
          <Link className="" to="/" style={{ border: '0' }} onClick={() => closeMobileNav()}>
            <img src={logo} alt="" className="responsive-img" style={{ marginTop: '15px' }} />
          </Link>
        </li>
        <NavLinks closeMobileNav={closeMobileNav} />
      </ul>
      <ArrowToTop />
    </div>
  );
};

const HomePageView = ({ noHero, children }) => {
  return (
    <div className="container hide-on-small-only">
      <div className={`${noHero ? 'headBgInterior' : 'headBg'}`}>
        <div className="nav-wrapper">{children}</div>
      </div>
    </div>
  );
};

const InterierPageView = ({ children }) => {
  return (
    <div className="themeBgColor center white-text" style={{ marginBottom: '20px' }}>
      <div className="container">
        <div className="nav-wrapper" style={{ height: '100px', color: 'white' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

const NavContent = ({ closeMobileNav, whiteText }) => {
  return (
    <header className="row" style={{ position: 'relative', zIndex: 100, margin: '0' }}>
      <nav className="transparent" style={topStyle}>
        <Link className="" to="/" style={{ position: 'absolute', top: 0, left: 0 }}>
          <img src={logo} alt="" className="logoAdj" />
        </Link>
        <a href="#!" data-target="mobile-nav" className="sidenav-trigger center show-on-medium-and-down">
          <i className="material-icons">menu</i>
        </a>
        <ul className="menu fix right hide-on-med-and-down" style={{ margin: '20px 30px 0px 0px' }}>
          <NavLinks closeMobileNav={closeMobileNav} />
        </ul>
      </nav>
    </header>
  );
};

export default React.memo(Home);
