import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
//import { FaArrowCircleUp } from "react-icons/fa";
import './App.css';

const ScrollArrow = () => {
   const [showScroll, setShowScroll] = useState(false);

   const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
         setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
         setShowScroll(false);
      }
   };

   const scrollTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
   };

   window.addEventListener('scroll', checkScrollTop);

   return (
      <i className="scrollTop fas fa-angle-up" onClick={scrollTop} style={{ display: showScroll ? 'flex' : 'none' }}></i>
      // <FaArrowCircleUp
      // 	className="scrollTop"
      // 	onClick={scrollTop}
      // 	style={{ height: 40, display: showScroll ? "flex" : "none" }}
      // />
   );
};

const ScrollToTopComponent = ({ history }) => {
   useEffect(() => {
      const unlisten = history.listen(() => {
         window.scrollTo(0, 0);
      });
      return () => {
         unlisten();
      };
   }, [history]);

   return null;
};

export const ScrollToTop = withRouter(ScrollToTopComponent);

// export ScrollToTop;

export default ScrollArrow;
