import React, { createContext, useState } from 'react';

export const SiteContext = createContext();

const SiteProvider = ({ children }) => {
   // const memorizedReducer = useCallback(() => createReducer(), []);
   const [state, setState] = useState({
      open: false,
   });

   const showContactForm = () => setState({ ...state, open: true });
   const hideContactForm = () => setState({ ...state, open: false });
   const toggleContactForm = () => setState({ ...state, open: state.open });

   const contextValues = {
      ...state,
      showContactForm,
      hideContactForm,
      toggleContactForm,
   };

   return <SiteContext.Provider value={contextValues}>{children}</SiteContext.Provider>;
};

export default SiteProvider;
