import React, { useEffect } from "react";
import { appendScript, removeScript } from "../../utils/scripts";
import Map1 from "./images/map1.jpg";
import Map2 from "./images/map2.jpg";
import I95 from "./images/I95.png";
import I16 from "./images/I16.png";

const HomePage = () => {
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	const s = {
		ratioBox: {
			height: 0,
			overflow: "hidden",
			paddingTop: "56.25%",
			position: "relative",
		},
		ratioBoxInner: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
		},
		ratioBoxInnerImg: {
			minWidth: "100%",
			minHeight: "100%",
			height: "auto",
			width: "auto",
		},
	};

	return (
		<div>
			<div className="" style={{ paddingBottom: "50px" }}>
				<div className="row container ">
					<div className="col s12 m12 l12 center">
						<h2
							className="abstractTextColor cursive"
							style={{ marginBottom: 0, paddingBottom: 0, marginTop: "20px" }}
						>
							MAPS / DIRECTIONS
						</h2>
					</div>
					<div className="col s12 m4 l5 center-align">
						<br />
						<img
							src={Map1}
							style={{ width: "100%", maxWidth: "600px" }}
							alt=""
						/>

						<img
							src={Map2}
							style={{ width: "100%", maxWidth: "600px" }}
							alt=""
						/>
					</div>
					<div className="col s12 m8 l7 center" style={{ marginTop: "20px" }}>
						<div className="left-align">
							<p>
								<img src={I95} style={{ float: "left", marginRight: "20px" }} />
								From Exit 5, take Hwy 17 north to traffic light. Turn right onto
								main st. (Hwy 46) travel approximately 5 miles to stop sign.
								Turn right at junction of Hwy 46 & 170. Follow signs to 315,
								continue straight on 315 for 3 miles and enter at Willow Bay.
								Look for the sign Hardeeville RV & Sites on your left.
							</p>

							<p>
								<img src={I95} style={{ float: "left", marginRight: "20px" }} />
								From Exit 8, turn right at yield sign onto Hwy 278. Turn left at
								stop sign on Hwy 17, go south towards Hardeeville. Got to
								traffic light, turn left onto Hwy 46 (main st). Turn right at
								junction of 46 & 170. Follow signs to 315, continue straight on
								315 for 3 miles and enter at Willow Bay. Look for the sign
								Hardeeville RV & Sites on your left.
							</p>

							<p>
								<b>
									If coming up from south I-95 please take exit 99A and follow
									I-16 directions{" "}
								</b>
							</p>

							<p>
								<img src={I16} style={{ float: "left", marginRight: "20px" }} />
								From I-16, take exit 166 to Hwy 17 north across the Talmadge
								Bridge into South Carolina. Turn right onto Hwy 315. Go
								approximately 4 miles, look for the sign Hardeeville RV & Sites
								on your right.
							</p>
						</div>
					</div>

					{/* <div className="col s12 center">
                  <div style={s.ratioBox}>
                     <div style={s.ratioBoxInner} className="activator">
                        <iframe
                           src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13506.131014242765!2d-81.03564805730015!3d32.189872297661736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fb9af64483b71d%3A0x51911dc2b8d6beef!2sHardeeville%20RV-%20Thomas%20Parks%20and%20Sites!5e0!3m2!1sen!2sus!4v1625775055831!5m2!1sen!2sus"
                           width="100%"
                           height="100%"
                           style={{ border: 0 }}
                           allowFullscreen=""
                           loading="lazy"
                        ></iframe>
                     </div>
                  </div>
               </div> */}
				</div>
			</div>
		</div>
	);
};

export default HomePage;
