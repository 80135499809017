import React, { useEffect, useState } from "react";
// import { Link } from 'react-router-dom';
import Road from "../../images/hero-road.jpeg";
import Rv from "../../images/icon-rv.png";
import Plug from "../../images/icon-plug.png";
import Dog from "../../images/icon-dog.png";
import Wifi from "../../images/icon-wifi.png";
import Fire from "../../images/icon-fire.png";
import DogPark from "../../images/dogpark-home.jpeg";
import { appendScript, removeScript } from "../../utils/scripts";
import { Link } from "react-router-dom";

const HomePage = (props) => {
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	return (
		<div className="">
			<div className="">
				<div className="row container center padAdj">
					<div className="themeBgColor white-text col s12 m12 l12">
						<br />
						<h1
							className="cursive"
							style={{ margin: "10px 0 0 0", paddingBottom: "0" }}
						>
							YOUR HOME AWAY FROM HOME
						</h1>
						<p className="content800" style={{ paddingTop: "0" }}>
							Just minutes from Beaufort, Sun City, Hilton Head and Savannah,
							Hardeeville RV is your ”home away from home.” The perfect extended
							stay that allows you to relax in a peaceful, wooded site
							surrounded by the sounds of nature. Campers from all over the USA
							and Canada come to stay with us again and again.
						</p>
						<br />
					</div>
					<img src={Road} className="imgAdj" />
				</div>
			</div>
			<div className="" style={{ marginBottom: "-5px" }}>
				<div className="row container center abstractTextColor">
					<ul className="groupIcon">
						<li>
							<Link to="/About-us">
								<img src={Rv} />
								<br />
								FULL LENGTH
								<br />
								PULL THROUGH
							</Link>
						</li>
						<li>
							<Link to="/About-us">
								<img src={Plug} />
								<br />
								20/30/50 AMP
								<br />
								SERVICE AVAILABLE
							</Link>
						</li>
						<li>
							<Link to="/About-us">
								<img src={Dog} />
								<br />
								LEASHED PETS
								<br />
								WELCOME
							</Link>
						</li>
						<li>
							<Link to="/About-us">
								<img src={Wifi} />
								<br />
								FREE WIFI
								<br />
								AND CABLE TV
							</Link>
						</li>
						{/* <li>
							<Link to="/About-us">
								<img src={Fire} />
								<br />
								FREE FIREWOOD
								<br />
								IF AVAILABLE
							</Link>
						</li> */}
					</ul>
				</div>
			</div>
			<div className="themeBgColor" style={{ marginBottom: "-5px" }}>
				<div className="row container center">
					<div className="themeBgColor white-text col s12 m12 l12">
						<br />
						<h1
							className="cursive"
							style={{ margin: "10px 0 0 0", paddingBottom: "0" }}
						>
							SO MUCH TO SEE AND DO, AND OH SO CLOSE
						</h1>
						<p className="col s12 m12 l10 offset-l1" style={{ marginTop: "0" }}>
							Just ten minutes from the Savannah Visitors Center, you’ll
							discover the many tours, sites and restaurants in the Historic
							District. Be sure to check out one of the Haunted Tours! Enjoy the
							history, lore and legends of Savannah, including a visit to
							Forsyth Park and Bonaventure Cemetery. Be sure to have lunch at
							Mrs. Wilkes Boarding House for a truly southern meal. And don’t
							forget to buy a copy of “the Book,” John Berendt’s, ‘Midnight in
							the Garden of Good and Evil’, a truly intriguing tale of fact and
							fiction of old Savannah!
						</p>
						<br />
					</div>
					<div className="col s12 m12 l10 offset-l1">
						<br />
						<img src={DogPark} style={{ width: "100%" }} />
						<div className="clearfix"></div>
						<br />
					</div>
				</div>
			</div>

			<div className="clearfix"></div>
		</div>
	);
};

export default HomePage;
