import React, { useEffect } from 'react';
import { appendScript, removeScript } from '../../utils/scripts';
import Image1 from './images/image1.jpg';
import Image2 from './images/image2.jpg';

const HomePage = () => {
  useEffect(() => {
    appendScript('/assets/js/css3-animate-it.js');

    return () => removeScript('/assets/js/css3-animate-it.js');
  }, []);

  const s = {
    ratioBox: {
      height: 0,
      overflow: 'hidden',
      paddingTop: '56.25%',
      position: 'relative',
    },
    ratioBoxInner: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
    },
    ratioBoxInnerImg: {
      minWidth: '100%',
      minHeight: '100%',
      height: 'auto',
      width: 'auto',
    },
  };

  return (
    <div>
      <div className="" style={{ paddingBottom: '50px' }}>
        <div className="row container ">
          <div className="col s12 m12 l12 center">
            <h2 className="abstractTextColor cursive" style={{ marginBottom: 0, paddingBottom: 0, marginTop: '20px' }}>
              RATES
            </h2>
          </div>
          <div className="col s12 center" style={{ marginTop: '20px' }}>
            <p>
              Call or text at <a href="tel:843-384-5696">843-384-5696</a> for our current rates.
            </p>
          </div>
          <div className="col s12 m8 offset-m2 l6 offset-l3 center">
            <div className="col s12 center">
              <img src={Image1} alt="" style={{ width: '100%' }} />
            </div>
            <div className="col s12 center">
              <img src={Image2} alt="" style={{ width: '100%' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
