import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const HomeLayout = (props) => {
	return (
		<div id="wrapper">
			<Header path={props.children.props.location.pathname} template="home" />
			{props.children}
			<Footer template="home" />
		</div>
	);
};

export default HomeLayout;
