import React from "react";
import Default from "./default";
import Home from "./home";

function index(props) {
	console.log(props.template);
	return props.template === "default" ? <Default /> : <Home />;
}

export default index;
