import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/materializeOverride.scss";
import "./styles/materialize.modern.admin.scss";
import App from "./app";
//import { Provider } from './context/auth-provider';
//import CartContextProvider from './context/CartContext';

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<App />
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);
