import React, { useEffect } from "react";
import { appendScript, removeScript } from "../../utils/scripts";
import OfficeHouse from "../../images/About-rvoffice.jpeg";
import Savannah from "./images/savannah.jpg";
import Beaufort from "./images/beaufort.jpg";
import HiltonHead from "./images/hiltonhead.jpg";
import Hardeeville from "./images/hardeeville.jpg";

const HomePage = () => {
	useEffect(() => {
		appendScript("/assets/js/css3-animate-it.js");

		return () => removeScript("/assets/js/css3-animate-it.js");
	}, []);

	const s = {
		ratioBox: {
			height: 0,
			overflow: "hidden",
			paddingTop: "56.25%",
			position: "relative",
		},
		ratioBoxInner: {
			position: "absolute",
			top: 0,
			left: 0,
			width: "100%",
			height: "100%",
			display: "flex",
			justifyContent: "center",
			alignContent: "center",
		},
		ratioBoxInnerImg: {
			minWidth: "100%",
			minHeight: "100%",
			height: "auto",
			width: "auto",
		},
	};

	return (
		<div>
			<div className="" style={{ paddingBottom: "50px" }}>
				<div className="row container ">
					<div className="col s12 m12 l12 center">
						<h2
							className="abstractTextColor cursive"
							style={{ marginBottom: 0, paddingBottom: 0, marginTop: "20px" }}
						>
							THINGS TO DO
						</h2>
					</div>
					<div className="col s12 m12" style={{ marginTop: "20px" }}>
						<div className="col s12 m6">
							<div className="card">
								<div className="card-image waves-effect waves-block waves-light">
									<div style={s.ratioBox}>
										<div style={s.ratioBoxInner} className="activator">
											<img
												className="activator"
												style={s.ratioBoxInnerImg}
												src={Savannah}
												alt=""
											/>
										</div>
									</div>
								</div>
								<div className="card-content">
									<span
										className="card-title activator grey-text text-darken-4"
										style={{ position: "relative" }}
									>
										Savannah
										<i
											className="material-icons right"
											style={{ right: "-10px", top: 0, position: "absolute" }}
										>
											more_vert
										</i>
									</span>
									<p>
										<a
											href="https://www.visitsavannah.com/"
											target="_blank"
											rel="noopener noreferrer"
										>
											www.visitsavannah.com
										</a>
									</p>
								</div>
								<div className="card-reveal">
									<span className="card-title grey-text text-darken-4">
										<h3 style={{ fontWeight: "bold" }}>
											The Historic City of Savannah, GA
											<i className="material-icons right">close</i>
										</h3>
									</span>
									<p>
										Located just 10 minutes away! Some things to do in Savannah
										include: Historical Savannah Carriage Tours, Wormsloe
										Historic Site, Tour the Historic Squares of Savannah, Paula
										Deen's Lady & Sons Restaurant, the International Trade
										Center, Mrs. Wilkes Boarding House Restaurant, River Street,
										Old Savannah Tours, and the Savannah National Wildlife
										Refuge.
									</p>
								</div>
							</div>
						</div>
						<div className="col s12 m6">
							<div className="card">
								<div className="card-image waves-effect waves-block waves-light">
									<div style={s.ratioBox}>
										<div style={s.ratioBoxInner} className="activator">
											<img
												className="activator"
												style={s.ratioBoxInnerImg}
												src={Beaufort}
												alt=""
											/>
										</div>
									</div>
								</div>
								<div className="card-content">
									<span
										className="card-title activator grey-text text-darken-4"
										style={{ position: "relative" }}
									>
										Beaufort
										<i
											className="material-icons right"
											style={{ right: "-10px", top: 0, position: "absolute" }}
										>
											more_vert
										</i>
									</span>
									<p>
										<a
											href="https://www.cityofbeaufort.org/"
											target="_blank"
											rel="noopener noreferrer"
										>
											www.cityofbeaufort.org
										</a>
									</p>
								</div>
								<div className="card-reveal">
									<span className="card-title grey-text text-darken-4">
										<h3 style={{ fontWeight: "bold" }}>
											Beaufort, SC<i className="material-icons right">close</i>
										</h3>
									</span>
									<p>
										Located just 25 minutes away! Some things to do in Beaufort
										include: Historic 1859 Lighthouse at Hunting Island, the
										Beaufort Museum, the John Mark Verdeir House, Spring Tours
										of Beaufortï¿½s Colonial Homes, Gullah Festival, Beaufort
										Fall Festival of Homes and History, Bay Street, the Hwy 21
										Drive-In Theater.
									</p>
								</div>
							</div>
						</div>
						<div className="col s12 m6">
							<div className="card">
								<div className="card-image waves-effect waves-block waves-light">
									<div style={s.ratioBox}>
										<div style={s.ratioBoxInner} className="activator">
											<img
												className="activator"
												style={s.ratioBoxInnerImg}
												src={HiltonHead}
												alt=""
											/>
										</div>
									</div>
								</div>
								<div className="card-content">
									<span
										className="card-title activator grey-text text-darken-4"
										style={{ position: "relative" }}
									>
										Hilton Head
										<i
											className="material-icons right"
											style={{ right: "-10px", top: 0, position: "absolute" }}
										>
											more_vert
										</i>
									</span>
									<p>
										<a
											href="https://www.hiltonheadisland.org/"
											target="_blank"
											rel="noopener noreferrer"
										>
											www.hiltonheadisland.org
										</a>
									</p>
								</div>
								<div className="card-reveal">
									<span className="card-title grey-text text-darken-4">
										<h3 style={{ fontWeight: "bold" }}>
											Hilton Head Island, SC
											<i className="material-icons right">close</i>
										</h3>
									</span>
									<p>
										Located just 25 minutes away! Some things to do in Hilton
										Head include: Golf, Golf, and More Golf- too many great
										courses to list! Gullah Heritage Trail Tours, Daufuskie
										Island, the beautiful beach, day spas, kayaking, and
										parasailing.
									</p>
								</div>
							</div>
						</div>
						<div className="col s12 m6">
							<div className="card">
								<div className="card-image waves-effect waves-block waves-light">
									<div style={s.ratioBox}>
										<div style={s.ratioBoxInner} className="activator">
											<img
												className="activator"
												style={s.ratioBoxInnerImg}
												src={Hardeeville}
												alt=""
											/>
										</div>
									</div>
								</div>
								<div className="card-content">
									<span
										className="card-title activator grey-text text-darken-4"
										style={{ position: "relative" }}
									>
										Hardeeville
										<i
											className="material-icons right"
											style={{ right: "-10px", top: 0, position: "absolute" }}
										>
											more_vert
										</i>
									</span>
									<p>
										<a
											href="https://jaspersc.org/"
											target="_blank"
											rel="noopener noreferrer"
										>
											www.jaspersc.org
										</a>
									</p>
								</div>
								<div className="card-reveal">
									<span className="card-title grey-text text-darken-4">
										<h3 style={{ fontWeight: "bold" }}>
											Hardeeville, SC
											<i className="material-icons right">close</i>
										</h3>
									</span>
									<p>
										And just 5 minutes away The Pink Pig Restaurant,
										specializing in Bar-B-Que. As featured in Southern Living
										Magazine, South Carolina Sandlapper and Abercrombie and
										Fitchï¿½s Quarterly, as well as many other magazines. The
										Food Network has been running a segment about The Pink Pig
										for several years. So stop by and try the best BBQ in the
										Low Country and Coastal Empire. Call for business hours -
										843.784.3635.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HomePage;
